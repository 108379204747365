/**
 * @package {PACKAGE NAME}
 * 
 * Entry point for Javascript package
 */

// Import CoreJS for polyfills
import "core-js/stable";
// Import Renerator runtime to enable Async functions
//import "regenerator-runtime/runtime";

// Import lib
// import fitText from './lib/fitText';
import ScrollOut from 'scroll-out';

// Import components
import Slider from './components/Slider';
import MenuController from './components/MenuController';
import './components/h5pAnswers';
import {courseFilter} from './components/courseFilter';

// Import misc items
import {ldSidebarController} from './misc/misc.main';

import Faq from './components/faq';

const faqTarget = document.querySelector('.faqOuterWrapper');
if (faqTarget) {
  new Faq(faqTarget, '.faqItem');
}

// init course filter
const courseFilterTarget = document.getElementById('courseFilter');
if (courseFilterTarget)
    courseFilter(courseFilterTarget);

// Initialize menu controller
const menuContainer = document.getElementById('menuContainer');
const menuOpener = document.getElementById('openNavigation');

new MenuController(menuContainer, menuOpener);

const lazyLoadInstance = new LazyLoad();

// Init slider
const sliderElements = document.getElementsByClassName('slider');
const sliderArray = [...sliderElements];

sliderArray.forEach(slider => {
    new Slider(slider);
});


// Make header text fit container
// const fitTarget = document.querySelector('.heroSection.home .heroSection__title');
// if (fitTarget)
//     window.fitText(fitTarget);

// Learndash logout redirect to profile
document.addEventListener('DOMContentLoaded', () => {
    const logoutBtn = document.querySelector('.header .ld-logout');
    if (logoutBtn) {
        logoutBtn.href = "/profiel/";
    }
})

/**
 * Old content copy pasted from S4U
 * Temp solution for the test phase
 */

document.addEventListener('DOMContentLoaded', () =>{

    const isH5P = document.getElementsByClassName('h5p-iframe-wrapper');
    if (!isH5P)
        return;
    
    function getFrames() {
        const iframes = document.getElementsByClassName('h5p-iframe');
        
        if (!iframes || iframes.length == 0) {
            setTimeout(function() {
                getFrames();
                return;
            } , 1000)
        } else {
            getButtons(iframes);
        }
    }
    
    function getButtons(iframes) {
        let H5P_answer_buttons = [];
    
        for(let i = 0; i < iframes.length; i++) {
            const buttons = iframes[i].contentWindow.document.getElementsByClassName('h5p-question-check-answer');
            if (!buttons || buttons.length == 0){
                setTimeout(function() {
                    getButtons(iframes);
                    return;
                } , 1000)
            } else {
                [].forEach.call(buttons, button => {
                    H5P_answer_buttons.push(button);
                })
        
            }
        }
    
        for (let i = 0; i < H5P_answer_buttons.length; i++) {
            H5P_answer_buttons[i].innerText = "Sla antwoord op";
        }
    }
    getFrames();
    })


/**
 * Learndash scripts
 */

// Login modal
const modalCloser = document.querySelector('.ld-login-modal .ld-modal-closer');
const modalLogin  = document.querySelector('.ld-login-modal-login');

(() => {

    if (modalLogin) {
        const modalRect = modalLogin.getBoundingClientRect();
        const closeRect = modalCloser.getBoundingClientRect();
        modalCloser.style.left = (modalRect.x + modalRect.width) - (closeRect.width / 2) + 'px';
        modalCloser.style.top = modalRect.y - (closeRect.height / 2) + 'px';
    }

})();

// Sidebar init
if (document.body.classList.contains('learndash-cpt')) {
    ldSidebarController.init();
}


// Download user reporting
const downloadBtnUserReporting = document.getElementById('download_user_report_btn');
if (downloadBtnUserReporting) {
    download_user_report_btn.addEventListener('click', e => {
        e.preventDefault();
        const targetUrl = "/wp-content/themes/samen-online-leren/includes/addons/user-reporting/user-reporting-download.php";

        window.location = targetUrl;
    })
}

/**
 * Initiate 3rd party libraries
 */

if (window.matchMedia("(max-width: 860px)")) {
  ScrollOut({
    once : true,
    threshold : 0
  });
} else {
  ScrollOut({
    once : true,
    threshold : 0.5
  });
}
