import gsap from 'gsap';

export const ldSidebarController = {
    active : false,
    init : function () {
        if (window.innerWidth <= 900 && !this.active) {
            this.start();
            this.active = true;
        }
        window.addEventListener('resize', function () {
            if (window.innerWidth <= 900 && !ldSidebarController.active) {
                ldSidebarController.start();
                ldSidebarController.active = true;
            }
            if (window.innerWidth >= 900 && ldSidebarController.active) {
                ldSidebarController.remove();
                ldSidebarController.active = false;
            }
        })
    },
    start : function () {
        const target = document.body
        initSidebar(target);
    },
    remove : function () {
        destroySidebar();
    }
}

const baseClass = `responsiveSidebar`;
const sidebar = document.querySelector('aside.sidebar');

function initSidebar(target) {
    sidebar.classList.add(baseClass);
    sidebar.dataset.isOpen = false;
    target.appendChild(sidebarTrigger());
}

function sidebarTrigger() {
    let trigger = document.createElement('div');
    trigger.classList.add(`${baseClass}__trigger`);
    trigger.setAttribute('id', 'sidebarTrigger');
    
    let innerTrigger = document.createElement('div');
    innerTrigger.classList.add(`${baseClass}__trigger-inner`);

    trigger.appendChild(innerTrigger);

    trigger.addEventListener('click', sidebarClickHandler);

    return trigger;
}

function sidebarClickHandler(event, target) {
    const switcher = sidebar.dataset.isOpen == "true";
    sidebar.dataset.isOpen = !switcher;
    const trigger = document.getElementById('sidebarTrigger');

    if (!switcher) {
        gsap.to(trigger, {
            x : sidebar.getBoundingClientRect().width,
            duration : 0.75,
            ease: "power1.inOut"
        })
        gsap.to(trigger.firstChild, {
            rotate: 90,
            duration : 0.75,
            ease: "power1.inOut"
        })
    } else {
        gsap.to(trigger, {
            x : 0,
            duration : 0.75,
            ease: "power1.inOut"
        })
        gsap.to(trigger.firstChild, {
            rotate: 270,
            duration : 0.75,
            ease: "power1.inOut"
        })
    }
}

function destroySidebar() {
    const trigger = document.getElementById('sidebarTrigger');
    sidebar.classList.remove('responsiveSidebar');
    trigger.remove();
}