export default class Slider {
    constructor (selector) {
        // Class storage container
        this.sliderData = {
            root        : selector,
            slides      : [],
            indicators  : [],
            images      : [],
            accents     : [],
            activeIndex : 0,
            maxIndex    : 0,
        }

        // Getter functions
        this.getSlideData();
        this.getIndicators();
        this.getImages();
        this.getAccents();

        if (this.sliderData.slides.length === 1) {
          selector.style.width = "125%";
          selector.style.transform = 'translateX(-12.5%)';
          selector.style.marginTop = '20rem';
          // this.goToIndex(0);
          const only = this.sliderData.slides[0].target;
          console.log(this.sliderData.slides[0]);
          // only.style.transform = 'none';
          // only.dataset.scroll = 'in';
          // this.setAccentSize();
          this.sliderData.accents[0].target.style.display = 'none';
          return;
        }

        // Initialization functions
        this.initEventListeners();

        // Set first item to be active
        this.goToIndex(0);
    }

    getSlideData() {
        // Get root from storage container
        const {root} = this.sliderData;

        // Get slides
        const   slides = root.getElementsByClassName('slider__slide'),
                slideArray = [...slides];

        // Push slide data to storage container
        slideArray.forEach((slide, index) => {
            this.sliderData.slides.push({
                index   : index,
                target  : slide,
            });
        });

        // Set the max index in the storage container
        this.sliderData.maxIndex = slideArray.length;
    }

    getIndicators() {
        // Get root from storage container
        const {root} = this.sliderData;

        // Get indicators
        const   indicators = root.getElementsByClassName('sliderController__indicator'),
                indicatorArray = [...indicators];

        // Push indicators to storage container
        indicatorArray.forEach((indicator, index) => {
            this.sliderData.indicators.push({
                index   : index,
                target  : indicator,
            });
        });
    }

    getImages() {
        // Get root from storage container
        const {root} = this.sliderData;

        // Find images within slides
        const   images = root.querySelectorAll('.slider__img'),
                imageArray = [...images];

        // Push indicators to storage container
        imageArray.forEach((image, index) => {
            this.sliderData.images.push({
                index   : index,
                target  : image,
            });
        });
    }
    
    getAccents() {
        // Get root from storage container
        const {root} = this.sliderData;

        // Get indicators
        const   accents = root.getElementsByClassName('slider__visual'),
                accentArray = [...accents];

        // Push indicators to storage container
        accentArray.forEach((accent, index) => {
            this.sliderData.accents.push({
                index   : index,
                target  : accent,
            });
        });
    }

    initEventListeners() {
        // Get indicators from storage container
        const {indicators, images, root, activeIndex} = this.sliderData;

        // Give all indicators a click event listener
        indicators.forEach(indicator => {
            indicator.target.addEventListener('click', event => { this.clickHandler(event); });
        })

        // Add key press event listener
        window.addEventListener('keyup', event => {
            if (!this.sliderData.isIntersecting)
                return;
                
            if (event.keyCode === 37) 
                this.goToIndex( (this.sliderData.activeIndex - 1) );

            else if (event.keyCode === 39)
                this.goToIndex( (this.sliderData.activeIndex + 1) );

            else
                return;
        })

        // Resize elements on content load
        document.addEventListener('DOMContentLoaded', () => {
            this.setAccentSize();
        })

        // Resize elements on resizing window
        window.addEventListener('resize', () => {
            this.setAccentSize();
        })

        // Add intersectionObserver to check if element becomes visible
        let imageObserver = new IntersectionObserver((entries, observe) => {
            this.setAccentSize();
        }, {});

        images.forEach(image => {
            imageObserver.observe(image.target);
        })

        // Add observer to only control 1 slider at a time
        let controlObserver = new IntersectionObserver((entries, observe) => {
            entries.forEach(entry => {
            if(entry.isIntersecting) 
                this.sliderData.isIntersecting = true;
            else
                this.sliderData.isIntersecting = false;
        })
        }, {});

        controlObserver.observe(root)

        // Touch move listener
        let touchStart = {
            x: 0,
            y: 0
        }
        root.addEventListener("touchstart", event => {
            touchStart = {
                x: event.changedTouches[0].screenX,
                y: event.changedTouches[0].screenY
            }
        }, {passive : true})
        root.addEventListener("touchend", event => {
            const touchEnd = {
                x: event.changedTouches[0].screenX,
                y: event.changedTouches[0].screenY
            }

            this.checkTouches(touchStart, touchEnd);
        }, {passive : true})
    }

    checkTouches(start, end) {
        const {activeIndex} = this.sliderData;
        const diff = {
            xPos: start.x - 100 > end.x,
            xNeg: start.x + 100 < end.x,
        }

        if (diff.xPos == true) 
            this.goToIndex(Number(activeIndex+1));

        else if (diff.xNeg == true)
            this.goToIndex(Number(activeIndex-1));
    }

    clickHandler(event) {
        // Get the target index
        const targetIndex = event.target.dataset.sliderIndex;

        // Go to the target index
        this.goToIndex(Number(targetIndex));
    }

    goToIndex(index) {
        // Check if index overflows max or min
        //console.log(this.checkIndexOverflow(index));
        index = this.checkIndexOverflow(index);

        // Get data from storage container
        const {slides, indicators, activeIndex} = this.sliderData;

        // Set current active to inactive
        slides[activeIndex].target.dataset.active = false;
        indicators[activeIndex].target.dataset.active = false;

        // Set the new active index to target index
        this.sliderData.activeIndex = index;

        // Set dataset values for slide and indicator
        slides[index].target.dataset.active = true;
        indicators[index].target.dataset.active = true;
        slides[index].target.style.transform = "";

        // Set transform for neighbors
        let neighborIndex = {
            next: this.checkIndexOverflow(index + 1),
            prev: this.checkIndexOverflow(index - 1)
        }

        slides[neighborIndex.next].target.style.transform = "translateX(100%)";
        slides[neighborIndex.prev].target.style.transform = "translateX(-100%)";

        this.setAccentSize();
    }

    checkIndexOverflow(index) {
        // Get max index from storage container
        const {maxIndex} = this.sliderData;

        // Check if target index overflows
        if (index >= maxIndex) 
            return 0;

        if (index < 0 )
            return maxIndex - 1;

        // Return index if within bounds
        return index;
    }

    setAccentSize() {
        // Get Slides and Acccents from storage container
        const {images, accents} = this.sliderData;

        // Loop over images and set size of accents
        images.forEach((image, index) => {
            if (!accents[index])
                return;
                
            accents[index].target.style.width = `${image.target.offsetWidth + 48}px`;
            accents[index].target.style.height = `${image.target.offsetHeight + 48}px`;
        })
    }
}