export function courseFilter(target) {
    const buttons = [...target.getElementsByClassName('courseFilter__button')];
    let filter = null;
    let categorys = [];
    let courses = [];

    [...document.querySelectorAll('.courseFilter .courseGrid__course')].forEach((course, index) => {
        const cat = course.dataset.slug.split("_");
        courses.push({
            target: course,
            cat : cat
        })
    });

    buttons.forEach(button => {
        categorys.push(button.dataset.slug);
        button.addEventListener("click", setFilter);
    });

    function setFilter(event) {

        buttons.forEach(button => {
            button.dataset.active = false;
        })

        if (event.target.dataset.slug != "")
            event.target.dataset.active = true;

        const slug = event.target.dataset.slug;
        filter = slug;

        filterResults();
    }

    function filterResults() {
        courses.forEach(course => {
            course.target.dataset.active = false;
            if (course.cat.includes(filter) || filter == "")
                course.target.dataset.active = true;
        })
    }
}