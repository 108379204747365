import gsap from 'gsap';

export default class MenuController {
  constructor(container, trigger) {
    this.data = {
      container: container,
      trigger: trigger,
      active: false,
      header: document.querySelector('header.header')
    }

    const itemHasChildren = [...document.querySelectorAll('.menu-item-has-children')];

    this.addEventListeners(itemHasChildren);
  }

  addEventListeners(itemHasChildren) {
    const {
      trigger
    } = this.data;

    trigger.addEventListener('click', event => {
      event.preventDefault();
      this.toggleMenu();
    })

    window.addEventListener('scroll', event => {
      this.scrollHandler();
    }, {
      passive: true
    })

    document.addEventListener('DOMContentLoaded', event => {
      this.scrollHandler();
    })

    if (itemHasChildren) {
      itemHasChildren.forEach(item => {
        item.dataset.active = false;
        item.addEventListener('click', (event) => {
          this.subItemTrigger(event, itemHasChildren)
        });
      })
    }
  }

  toggleMenu() {
    const {
      container,
      trigger
    } = this.data;

    this.data.active = trigger.dataset.active == 'true';

    container.dataset.active = !this.data.active;
    trigger.dataset.active = !this.data.active;
  }

  scrollHandler() {
    const {
      header
    } = this.data;

    if (window.scrollY >= header.offsetHeight)
      header.dataset.scrolled = true;
    else
      header.dataset.scrolled = false;
  }

  subItemTrigger(event, itemHasChildren) {

    itemHasChildren.forEach(item => {
      if (item != event.target)
        item.dataset.active = false;
    })

    if (event.target.tagName.toLowerCase() !== 'a') {
      const isActive = event.target.dataset.active == 'true';
      event.target.dataset.active = !isActive;
    }
  }
}