/* eslint-disable no-param-reassign */
class Faq {
  itemHeights = [];

  constructor(target, selector) {
    this.items = Array.from(target.querySelectorAll(selector));
    this.addEvents();
  }

  addEvents() {
    this.items.forEach((item, index) => {
      // @ts-ignore
      this.itemHeights.push(item.querySelector('.faqItem__content').getBoundingClientRect().height);
      // @ts-ignore
      item.querySelector('.faqItem__content').style.height = '0px';

      item.addEventListener('click', () => {
        this.activeItem = index;
        const toggle = item.dataset.active === 'true';
        const toggleInv = `${!toggle}`;
        console.log(toggleInv);
        this.setActiveItem(toggleInv);
      });
    });
  }

  setActiveItem(toggle) {
    const activeItem = this.items[this.activeItem];
    this.items.forEach((item) => {
      // @ts-ignore
      item.querySelector('.faqItem__content').style.height = '0px';
      item.dataset.active = 'false';
    });

    if (toggle === 'true') {
      // @ts-ignore
      activeItem.querySelector('.faqItem__content').style.height = `${(this.itemHeights[this.activeItem] + 30)}px`;
      activeItem.dataset.active = 'true';
    } else {
      // @ts-ignore
      activeItem.querySelector('.faqItem__content').style.height = '0px';
      activeItem.dataset.active = 'false';
    }
  }
}

export default Faq;
